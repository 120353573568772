import React, { useEffect, useState } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  RedditIcon,
  RedditShareButton,
} from 'react-share';
import Modal from '../Modal/Modal';
import css from './ShareButtonModal.module.css';

const ShareButtonModal = props => {
  const { openShareModal, setOpenShareModal, onManageDisableScrolling } = props;
  const [isCopied, setCopied] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  useEffect(() => {
    setShareUrl(window?.location ? window.location.href : '');
  }, [openShareModal]);

  const handleCopyTextToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Modal
      id="ListingPage.shareModal"
      isOpen={openShareModal}
      onClose={() => setOpenShareModal(false)}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.shareModal}>
        <h3>Share</h3>
        <div className={css.copyField}>
          <p>{shareUrl}</p>
          <button onClick={handleCopyTextToClipboard}>{isCopied ? 'Copied' : 'Copy'}</button>
        </div>
        <label>Or Share Via</label>
        <div className={css.iconWrapper}>
          <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={'Twitter'}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <WhatsappShareButton url={shareUrl} className="Demo__some-network__share-button">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <FacebookMessengerShareButton url={shareUrl} className="Demo__some-network__share-button">
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
          <TelegramShareButton url={shareUrl} className="Demo__some-network__share-button">
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <RedditShareButton url={shareUrl} className="Demo__some-network__share-button">
            <RedditIcon size={32} round />
          </RedditShareButton>
        </div>
      </div>
    </Modal>
  );
};

export default ShareButtonModal;

import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const FETCH_GIFTS_LISTINGS_REQUEST = 'app/GiftsPage/FETCH_GIFTS_LISTINGS_REQUEST';
export const FETCH_GIFTS_LISTINGS_SUCCESS = 'app/GiftsPage/FETCH_GIFTS_LISTINGS_SUCCESS';
export const FETCH_GIFTS_LISTINGS_ERROR = 'app/GiftsPage/FETCH_GIFTS_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryInProgress: false,
  queryError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const GiftsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_GIFTS_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        queryError: null,
        currentPageResultIds: [],
      };
    case FETCH_GIFTS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_GIFTS_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryError: payload,
      };

    default:
      return state;
  }
};

export default GiftsPageReducer;

// ================ Action creators ================ //

export const queryGiftsListingsRequest = () => ({
  type: FETCH_GIFTS_LISTINGS_REQUEST,
});

export const queryGiftsListingsSuccess = response => ({
  type: FETCH_GIFTS_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryGiftsListingsError = e => ({
  type: FETCH_GIFTS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const queryGiftsListings = params => (dispatch, getState, sdk) => {
  dispatch(queryGiftsListingsRequest());

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryGiftsListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryGiftsListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const finalParams = queryParams?.tag
    ? { pub_tags: queryParams.tag }
    : { pub_tags: `has_all:${queryParams.tags}` };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryGiftsListings({
    ...finalParams,
    page: 1,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
